import { Fragment, useEffect, useState } from "react";
import JoinAndRenewServices from "../../../../../services/api/joinAndRenew";
import GhinTable from "../../../../shared/GhinTable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addAlert } from "../../../../shared/actions"
import JoinPagesDetails from "./JoinPagesDetails";
import ConfirmationModalTypeB from "../../../../Modals/ConfirmationModalTypeB";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";

function JoinPages(props) {
  const history = useHistory();
  const [tableData, set_tableData] = useState([])
  const [tableLoading, set_tableLoading] = useState(false)
  const [selectedLink, set_selectedLink] = useState()
  const [deleteLinkModalOpened, set_deleteLinkModalOpened] = useState(false)
  const [expandedIndex, set_expandedIndex] = useState(-1)
  const [displayDetails, set_displayDetails] = useState(false)
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pages, setPages] = useState(null);
  const [total, setTotal] = useState(null);
  
  useEffect(() => {
    set_tableLoading(true)
    let params = {}
    params['page'] = page + 1;
    params['per_page'] = perPage;
    
    JoinAndRenewServices.getJoinPages(props.associationId, params).then(res => {
      set_tableData(res.join_pages)
      set_tableLoading(false)
      setTotal(parseInt(res["total-count"], 10));
      setPerPage(parseInt(res["per-page"], 10));
      setPages(parseInt(res["total-pages"], 10));
    })
  }, [props.associationId, page, perPage])
  
  const reloadPages = () => {
    const backup = displayDetails
    set_displayDetails(false)
    set_tableLoading(true)
    let params = {}
    params['page'] = page + 1;
    params['per_page'] = perPage;

    JoinAndRenewServices.getJoinPages(props.associationId, params).then(res => {
      set_tableData(res.join_pages)
      set_tableLoading(false)
      set_displayDetails(backup)
      setTotal(parseInt(res["total-count"], 10));
      setPerPage(parseInt(res["per-page"], 10));
      setPages(parseInt(res["total-pages"], 10));
    })
  }

  const columns = [
    {
      Header: "Page Name",
      thClassName: 'table-align-center',
      Cell: data => {
        return <p className={`link-name-${data.original.id}`}> {data.original.name} </p>
      }
    },
    {
      Header: "Setup Status",
      colClassName: 'col_username',
      className: 'table-align-center',
      thClassName: 'table-align-center',
      Cell: data => {
        if (data.original.has_credentials && data.original.has_memberships) {
          return <p style={{ color: "green" }}>Complete</p>
        }

        return <div className="center-join-pages-logos">
          <a href="#donothing" onClick={(e) => { e.preventDefault() }} data-tip data-for={`join-page-${data.original.id}`}><i className="material-icons v-mid m-left yellow">info</i></a>
          <ReactTooltip id={`join-page-${data.original.id}`} className="table-head__tooltip"  place='bottom' effect={'float'}>
            <div style={{padding: '10px 0px'}}>
              <p>The following information is required to complete the setup of this join page. The steps already marked are complete</p>
              <br></br>
              <div className='row'> 
                {!data.original.has_credentials && <i className="material-icons v-mid m-left green">radio_button_unchecked</i>}
                {data.original.has_credentials && <i className="material-icons v-mid m-left green">check_circle</i>}
                <p style={{paddingLeft: '3px'}}>Commerce Provider Credentials</p>
              </div>
              <div className='row'> 
                {!data.original.has_memberships && <i className="material-icons v-mid m-left green">radio_button_unchecked</i>}
                {data.original.has_memberships && <i className="material-icons v-mid m-left green">check_circle</i>}
                <p style={{paddingLeft: '3px'}}>Club Membership Fees for at least 1 club need to be added.</p>
              </div>
            </div>
          </ReactTooltip>
          <p style={{color: '#FF9900', paddingLeft: '3px'}}> Incomplete</p>
        </div>
      }
    },
    {
      Header: "Active?",
      className: 'table-align-center',
      thClassName: 'table-align-center',
      colClassName: 'col_status',
      Cell: data => {
        return <Fragment>
          <input type="checkbox"
            disabled={!props.canEditAssociation}
            defaultChecked={data.original.is_enabled}
          />
          <label
            style={{ margin: 'auto', "height": "40px" }}
            onClick={() => {
              set_tableLoading(true)
              const params = {
                is_enabled: !data.original.is_enabled
              }
              JoinAndRenewServices.updateJoinPage(data.original.golf_association_id, data.original.id, params).then(() => {
                props.addAlert({
                  type: 'success',
                  message: `Join Link successfully ${data.original.is_enabled ? "disabled" : "enabled"}`
                });
                reloadPages()
              }).catch((err) => {
                set_tableLoading(false)
                if (err.data.errors) {
                  const error = err.data.errors
                  props.addAlert({
                    type: 'error',
                    message: error[Object.keys(error)[0]]
                  });
                }
                if (err.data.error) {
                  try {
                    console.log(err.data.error)
                    props.addAlert({
                      type: 'error',
                      message: err.data.error
                    });
                  }
                  catch {
                    props.addAlert({
                      type: 'error',
                      message: "An error has occurred"
                    });
                  }
                }
              })
            }}
          ></label>
        </Fragment>
      }
    },
    {
      Header: "Association Page URL",
      accessor: "url",
      colClassName: 'col_website',
      thClassName: 'table-align-center',
      Cell: props => {
        return <a href={`${props.value}`} > {props.value}</a>
      }
    },
    {
      colClassName: 'col_club-name',
      Cell: data => {  
        const enabled = data.original.has_credentials && data.original.has_memberships;
        return <Fragment>
          <span>
            {data.original.is_enabled ?
              <button className="btn fill blue"
                disabled={!enabled}
                style={{ width: "95px" }}
                onClick={() => {
                  navigator.clipboard.writeText(data.original.url);
                  props.addAlert({
                    type: 'success',
                    message: `Join Link copied to clipboard`
                  });
                }}
              >Copy Link</button>
              :
              <button
                disabled={!props.canEditAssociation}
                className="btn lnk"
                style={{ width: "95px", alignSelf: "center", color: !props.canEditAssociation && 'transparent' }}
                onClick={() => {
                  set_selectedLink(data.original)
                  set_deleteLinkModalOpened(true)
                }}>
                Delete
              </button>
            }
            <button className="btn fill green" style={{ width: "95px", marginLeft: "10px" }}
              onClick={() => {
                if (expandedIndex === data.index && displayDetails) {
                  set_displayDetails(false)
                }
                else {
                  set_expandedIndex(data.index)
                  set_displayDetails(true)
                }
              }}
            >Details</button>
          </span>
        </Fragment>
      }
    },
    {
      expander: true,
      show: false
    }
  ]
  
  const updateJoinPageTableData = (join_page_id, data) => {
    let join_pages = tableData;
    join_pages.forEach((item, i) => {
      if (item.id === join_page_id)
        join_pages[i] = {...item, ...data}
    });
    set_tableData(join_pages);
  }
  
  return (
    <Fragment>
      <div className="columns">
        <div className="row">
          <div className="panel">
            <div className="panel__head">
              Manage Join Pages
            </div>
            <div className="panel__body">
              {props.canEditAssociation && <div className="row">
                <div className="col">
                  <span><strong>Note:</strong> Create as many join pages as you need by pressing the "Add Join Page" button. To edit an existing join page, press the "Details" button.</span>
                </div>
                <div className="col auto push-right">
                  <button className="btn fill green"
                    onClick={() => {
                      history.push("./join-pages/create");
                    }}
                  >Add Join Page</button>
                </div>
              </div>}
              <div className="row">
                <div className="col jc-fs">
                  <GhinTable
                    idKey={'id'}
                    hideSelect={true}
                    loading={tableLoading}
                    data={tableData}
                    columns={columns}
                    sortable={false}
                    pages={pages}
                    page={page}
                    total={total}
                    pageSize={perPage}
                    manual
                    onPageSizeChange={(size, index) => {
                      setPerPage(size);
                      setPage(index);
                    }}
                    onPageChange={(index) => setPage(index)}
                    displayMembershipDetails={displayDetails}
                    expandedIndex={expandedIndex}
                    SubComponent={row => {
                      return <JoinPagesDetails
                        data={row.original}
                        updateJoinPageTableData={updateJoinPageTableData}
                        canEditAssociation={props.canEditAssociation}
                        reloadPages={reloadPages}
                      />
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deleteLinkModalOpened && <ConfirmationModalTypeB
        openModal={true}
        wideClass={"narrow"}
        onConfirmAction={() => {
          JoinAndRenewServices.deleteJoinPage(selectedLink.golf_association_id, selectedLink.id).then(() => {
            reloadPages()
            set_deleteLinkModalOpened(false)
            props.addAlert({ type: 'success', message: 'Join Link successfully deleted.' })
          })
        }}
        modalIcon={"help_outline"}
        onCancelAction={() => {
          set_deleteLinkModalOpened(false)
        }}
        closeModal={() => {
          set_deleteLinkModalOpened(false)
        }}
        infoText={[`Are you sure you want to delete the ${selectedLink.name} join link?`]}
        cancelLabel={"No"}
        confirmLabel={"Yes"}
      />
      }
    </Fragment>
  )
}

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ addAlert }, dispatch);
  return {
    dispatch,
    ...actions
  };
}

export default connect(null, mapDispatchToProps)(JoinPages)
